import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from 'react-router-dom';
import './config';
import 'bootstrap/dist/css/bootstrap.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './AlertTemplate';
import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: "https://7fb3c8293f2349738b9885b8bf0baa13@o932499.ingest.sentry.io/4504327313162240",
  environment: global.config.sentry_environment,
  // integrations: [new Integrations.BrowserTracing()],
//   routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0,
  // integrations: [
	// 			new Sentry.Replay({
	// 				maskAllText: true,
  //     				blockAllMedia: true,
	// 			})],
});

const options = {
  offset: '30px',
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 100001,
  },
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
