import React, { useEffect, useState } from "react";
import { all_legal_routes } from "../config";
import general_fetch from "../utils/fetch";
import './styles.css';
import { handleError } from "../utils/errors";
import { get_display_name } from "../utils/string";
import { useHistory } from "react-router-dom";
import { get_roles, user_has_access_to_portal } from "../utils/user";

const Portals = ({ org_key, user_details, set_user_details, org_key_id, org_key_logo, org_key_name }) => {
    const history = useHistory()
    const [user_roles, set_user_roles] = useState([]);

    const get_role_name = (role) => {
        try {
            switch (role) {
                case "designer":
                    return "Designer";
                case "accounts_manager":
                    return "Account manager";
                case "catalog_manager":
                    return "Catalogue manager";
                case "project_manager":
                    return "Project manager";
                case "production_designer":
                    return "Production Designer";
                case "mes_operator":
                    return "MES Operator";
                case "mes_admin":
                    return "MES Admin";
                default:
                    break;
            }
            return ""
        } catch (err) {
            console.error(`Error in get_role_name `, err)
            handleError(err);
        }
    }

    const init = async () => {

        var response = "";
        try {
            // TODO make sure response gets correct value
            console.log('User details loaded')
            response = await general_fetch({ url: '/user/get_details', data_field: 'user' });
            set_user_details(response.user_details)
        } catch (err) {
            console.log(err);
            let origin = window.location.origin;
            let org_key = window.location.pathname.split('/')[1]
            if (all_legal_routes.includes(org_key)) {
                org_key = "";
            }
            window.location.href = `${origin}/${org_key}`
        }
    }

    const onClickPortalCard = (portal) => {
        try {
            if (!isDisabledPortalCard(portal)) {
                let target = "";
                switch (portal) {
                    case "admin":
                        target = global.config.admin_path;
                        break;
                    case "mes":
                        target = global.config.mes_path;
                        break;
                    case "design":
                        target = global.config.design_path;
                        break;
                    default:
                        break;
                }
                if (target) {
                    window.location.href = target;
                }
            }
        } catch (err) {
            console.error(`Error in onClickPortalCard `, err)
            handleError(err);
        }
    }

    const disabledMessage = (portal) => {
        switch (portal) {
            case "admin":
                return (
                    <div>
                        you need  <b>Account Manager or Catalog Manager or Project Manager</b> access to enter
                    </div>
                )
                break;
            case "mes":
                return (
                    <div>
                        you need <b>MES Editor or MES User</b> access to enter
                    </div>
                )
                break;
                case "design":
                    return (
                        <div>
                        you need  <b>Designer</b> access to enter
                    </div>
                )
                break;
            default:
                break;
        }
        
        return ''
            
    }

    const isDisabledPortalCard = (portal) => {
        try {
            return !user_has_access_to_portal(user_details, portal)
        } catch (err) {
            console.error(`Error in isDisabled `, err)
            handleError(err);
        }
    }

    const logout = async () => {
        try {
            await general_fetch({ url: '/user/logout' })
            window.location.href = (`${window.location.origin}/${org_key ? org_key : ''}`)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (!(user_details && user_details.id)) {
            init()
        }
    }, []);

    useEffect(() => {
        if (user_details && user_details.id && user_details.id != 6) {
            let roles = get_roles(user_details);
            set_user_roles(roles)
        }else if(user_details && user_details.id == 6){
            window.location.href = (`${window.location.origin}/${org_key ? org_key : ''}`)
        }
    }, [user_details]);

    return (
        user_details && user_details.id && user_details.id != 6 ?
            <div className="inf-portals-container" style={{ height: '100%', width: '100%', background: '#F5F6F8', display: 'flex', flexDirection: 'column', padding: '48px' }}>
                <div className="inf-portals-info">
                    <div>{`Hello ${user_details.first_name}`}</div>
                    <img height='48px' src={org_key_logo} />
                    <div className="inf-secondary-text">{`Your Access : ${user_roles.length ? user_roles.map(role => get_role_name(role)).filter(x => x).join(', ') : '-'}`}</div>
                </div>

                <div className="inf-portals-selector">
                    <div className="inf-secondary-text">Please select a portal you would like to work on</div>
                    <div className="inf-portals-card-container">
                        <div onClick={() => onClickPortalCard('admin')} className={`portal_card ${isDisabledPortalCard('admin') ? 'disabled_card' : ''}`}>
                            <div className="portal_card_heading">Admin Portal</div>
                            <img src={'/admin.gif'} />
                            <div className="portal_card_info_text">For admins to manage users, billing, catalog and preferences.</div>
                            <div className="portal_card_disable_message">
                                {disabledMessage('admin')}
                            </div>
                        </div>

                        <div onClick={() => onClickPortalCard('design')} className={`portal_card ${isDisabledPortalCard('design') ? 'disabled_card' : ''}`}>
                            <div className="portal_card_heading">Design Portal</div>
                            <img src={'/design.gif'} />
                            <div className="portal_card_info_text">For designers to create and manage designs</div>
                            <div className="portal_card_disable_message">
                                {disabledMessage('design')}
                            </div>
                        </div>

                        <div onClick={() => onClickPortalCard('mes')} className={`portal_card ${isDisabledPortalCard('mes') ? 'disabled_card' : ''}`}>
                            <div className="portal_card_heading">Mes Portal</div>
                            <img src={'/mes.gif'} />
                            <div className="portal_card_info_text">For production designers and machine users to create and manage work orders and access machine outputs</div>
                            <div className="portal_card_disable_message">
                                {disabledMessage('mes')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="inf-portal-logout inf-secondary-text" onClick={logout}>
                    <img src={'/logout.svg'} />
                    Logout
                </div>
            </div>
            :
            <img alt='Not Available' src="/resources/images/site_image.jpeg" style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%' }} />
    )
}

export default Portals